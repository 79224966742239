<template>
  <div class="mx-1">
    <div class="suitcase-header mt-4" v-if="suitcase_report">
      <div
        class="left-header-text"
        :style="{
          'background-color': facultyColor + '!important',
          color: $lightOrDark(facultyColor) ? 'black' : 'white',
        }"
      >
        <h1
          class="font-weight-bold text-center"
          :style="{ 'background-color': facultyColor }"
        >
          PA
        </h1>
      </div>
      <h4 class="suitcase-faculty text-left">
        {{ suitcase_report.faculty_name }}
      </h4>
      <div class="logo-container">
        <h4 class="suitcase-title mr-2">
          MALETA <span class="suitcase-title-2">DIDÁCTICA</span>
        </h4>
        <div class="logo-section ml-3">
          <b-img class="institution-logo" :src="institution_logo"></b-img>
        </div>
      </div>
    </div>
    <!-- Encabezado principal con información de asignatura -->
    <div class="synthesis-header-container">
      <div
        class="empty-block"
        :style="{
          'background-color': suitcase_report
            ? facultyColor + '!important'
            : '',
        }"
      ></div>
      <h5 class="synthesis-header mb-0 py-2 text-white">
        <strong>PROGRAMA DE ASIGNATURA</strong>
      </h5>
    </div>
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ $getVisibleNames("manual.matter_code", false, "Código") }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >Nombre</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea de Formación"
              )
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_code }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_name }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.sct_credits }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              suitcase_report.duoc_credits ? suitcase_report.duoc_credits : "-"
            }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            <template v-if="suitcase_report.modalities.length > 0">
              <div
                v-for="modality in suitcase_report.modalities"
                :key="`modality-${modality.id}`"
                :variant="$lightOrDark(facultyColor) ? 'dark' : 'light'"
                class="mr-1"
              >
                {{ modality.name }}
              </div>
            </template>
            <template v-else>
              <div>N/A</div>
            </template></b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{ suitcase_report.formation_line }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE ANTECEDENTES GENERALES ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="9" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="list-check"></b-icon>
            </span>
            1. ANTECEDENTES GENERALES
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="second-header">Horas Totales</b-th>
          <b-th class="second-header">Docencia Dirigida Presencial</b-th>
          <b-th class="second-header">Horas Pedagógicas</b-th>
          <b-th class="second-header">Docencia Dirigida No Presencial</b-th>
          <b-th class="second-header">Horas Pedagógicas</b-th>
          <b-th class="second-header">Prerrequisitos</b-th>
          <b-th class="second-header"
            >Horas Cronológicas de Trabajo Autónomo</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <div>
              {{ suitcase_report.total_hours }}
            </div>
          </b-td>
          <b-td>
            <template v-if="suitcase_report.in_person_environments.length > 0">
              <span>
                {{
                  suitcase_report.in_person_environments
                    .map((x) => x.name)
                    .join(", ")
                }}
              </span>
            </template>
            <template v-else>N/A</template>
          </b-td>
          <b-td>
            {{ suitcase_report.in_person_hours }}
          </b-td>
          <b-td>
            <template v-if="suitcase_report.online_environments.length > 0">
              <b-badge
                v-for="environment in suitcase_report.online_environments"
                :key="`online_environments-${environment.id}`"
                variant="info"
                class="mr-1"
              >
                {{ environment.name }}
              </b-badge>
            </template>
            <template v-else>N/A</template>
          </b-td>
          <b-td>
            {{ suitcase_report.online_hours }}
          </b-td>
          <b-td>
            <template v-if="suitcase_report.prerequisites.length > 0">
              <span>
                {{
                  suitcase_report.prerequisites
                    .map((prerequisite) => prerequisite.matter__name)
                    .join(", ")
                }}
              </span>
            </template>
            <template v-else>
              <div class="text-center">Sin Prerrequisitos</div>
            </template>
          </b-td>
          <b-td>
            {{ suitcase_report.autonomous_work_hours }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE DESCRIPCIÓN DE LA ASIGNATURA ***********************-->
    <b-table-simple class="mb-0" v-if="suitcase_report" bordered responsive>
      <b-thead>
        <b-tr>
          <b-th colspan="2" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="gear"></b-icon>
            </span>
            2. DESCRIPCIÓN DE LA ASIGNATURA
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report && suitcase_report.competence_dict_list.length > 0
          "
        >
          <b-tr>
            <b-td class="second-header text-justify">
              <strong> Capacidad/es Institucionales </strong>
            </b-td>
            <!-- Capacidades Institucionales -->
            <b-td class="text-justify">
              <div v-if="suitcase_report.capacities.length == 0">
                No hay Capacidad/es Institucionales para la Asignatura.
              </div>
              <div v-else>
                <div>
                  {{ suitcase_report.capacities.join(", ") }}
                </div>
                <!-- <div
                v-for="(capacity, index) in suitcase_report.capacities"
                :key="index"
              >
                {{ capacity }}
              </div> -->
              </div>
            </b-td>
          </b-tr>
          <template
            v-for="competence_dict_list in suitcase_report.competence_dict_list"
          >
            <template
              v-for="(competence, index) in competence_dict_list.competences"
            >
              <b-tr
                :key="
                  'ep-' +
                  competence_dict_list.egress_profile_name +
                  '-' +
                  competence.id
                "
              >
                <b-td
                  v-if="index == 0"
                  :rowspan="competence_dict_list.competences.length"
                  class="text-justify align-middle second-header"
                >
                  <strong>
                    Capacidades y Competencias del Programa
                    <template
                      v-if="suitcase_report.competence_dict_list.length > 1"
                    >
                      de "{{ competence_dict_list.egress_profile_name }}"
                    </template>
                  </strong>
                </b-td>
                <b-td class="text-justify ml-4">
                  <span v-if="competence.order">{{
                    "C" + competence.order + "."
                  }}</span>
                  {{ competence.full_sentence }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <!-- <template
            v-for="(competence, index) in suitcase_report.linked_competences"
          >
            <b-tr :key="competence.id">
              <b-td
                v-if="index == 0"
                :rowspan="suitcase_report.linked_competences.length"
                class="text-center align-middle second-header"
              >
                <strong>
                  {{
                    $getVisibleNames("mesh.competence", true, "Competencias")
                  }}
                  {{
                    egressProfileLinked ? 'de "' + egressProfileLinked.name : ""
                  }}
                </strong>
              </b-td>
              <b-td class="text-left ml-4">
                <span v-if="competence.order">{{
                  "C" + competence.order + "."
                }}</span>
                {{ competence.full_sentence }}</b-td
              >
            </b-tr>
          </template> -->
        </template>
      </b-tbody>
    </b-table-simple>
    <b-table-simple v-if="suitcase_report" bordered responsive class="mt-2">
      <b-thead>
        <b-tr>
          <b-th class="second-header">RESULTADO DE APRENDIZAJE</b-th>
          <b-th class="second-header"> INDICADOR DE LOGRO</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="learning_result in suitcase_report.learning_results">
          <template
            v-for="(
              evaluation_criteria, index2
            ) in learning_result.evaluation_criterias_micro"
          >
            <b-tr
              :key="'RA-' + learning_result.id + '-' + evaluation_criteria.id"
            >
              <b-td
                class="text-justify align-middle"
                v-if="index2 == 0"
                :rowspan="learning_result.evaluation_criterias_micro.length"
              >
                <span v-if="learning_result.order">{{
                  "RA" + learning_result.order + "."
                }}</span>
                {{ learning_result.full_sentence }}
              </b-td>
              <b-td class="text-justify">
                <span v-if="learning_result.order">{{
                  "IL" + learning_result.order + "." + evaluation_criteria.order
                }}</span>
                {{ evaluation_criteria.full_sentence }}
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-if="suitcase_report.learning_results.length == 0">
          <b-td class="text-center" colspan="2"
            >No hay Descripción de la Asignatura</b-td
          >
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE SÍNTESIS DE LA RUTA DE APRENDIZAJE  ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="6" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="clipboard-data"></b-icon>
            </span>
            3. SÍNTESIS DE LA RUTA DE APRENDIZAJE
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="thirth-header">RESULTADO DE APRENDIZAJE</b-th>
          <b-th class="thirth-header"> INDICADOR DE LOGRO</b-th>
          <b-th class="thirth-header"> EXPERIENCIA DE APRENDIZAJE</b-th>
          <b-th class="thirth-header"> ACTIVIDADES Y EVALUACIONES </b-th>
          <b-th class="thirth-header"> AMBIENTES DE APRENDIZAJE </b-th>
          <b-th class="thirth-header"> N° HORAS </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-for="(
            learning_result, index1
          ) in suitcase_report.learning_experiences"
        >
          <template
            v-if="
              learning_result.activities.length > 0 &&
              learning_result.evaluations.length > 0
            "
          >
            <template v-for="(activity, index2) in learning_result.activities">
              <b-tr :key="'learning-result' + index1 + 'activity' + index2">
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="`study_unit-${study_unit.id}`"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge1"
                      v-for="study_unit in learning_result.study_units"
                      :key="'p-lrs1' + study_unit.id"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <b-badge
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="`evaluation_criteria-${evaluation_criteria.id}`"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge1"
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="'p-ecs1' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                  <!-- <p
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Act {{ index1 + 1 }}.{{ index2 + 1 }} - -->
                  {{ activity.name }}
                </b-td>
                <b-td
                  :class="[
                    activity.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="activity.study_environments.length > 0">
                    <span>
                      {{
                        activity.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ activity.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
            <template
              v-for="(evaluation, index3) in learning_result.evaluations"
            >
              <b-tr :key="'learning-result' + index1 + 'evaluation' + index3">
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="`evaluation_criteria-${evaluation_criteria.id}`"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge2"
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="'p-ecs2' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Ev {{ evaluation.type_name }} {{ index3 + 1 }} - -->
                  {{ evaluation.name }}
                </b-td>
                <b-td
                  :class="[
                    evaluation.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="evaluation.study_environments.length > 0">
                    <span>
                      {{
                        evaluation.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ evaluation.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else-if="learning_result.activities.length > 0">
            <template v-for="(activity, index2) in learning_result.activities">
              <b-tr :key="'learning-result' + index1 + 'activity' + index2">
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="`study_unit-${study_unit.id}`"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge2"
                      v-for="study_unit in learning_result.study_units"
                      :key="'p-lrs2' + study_unit.id"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="`act-evaluation_criteria-${evaluation_criteria.id}`"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge3"
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="'p-ecs3' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Act {{ index1 + 1 }}.{{ index2 + 1 }} - -->
                  {{ activity.name }}
                </b-td>
                <b-td
                  :class="[
                    activity.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="activity.study_environments.length > 0">
                    <span>
                      {{
                        activity.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ activity.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else-if="learning_result.evaluations.length > 0">
            <template
              v-for="(evaluation, index3) in learning_result.evaluations"
            >
              <b-tr :key="'learning-result' + index1 + 'evaluation' + index3">
                <b-td
                  class="text-left align-middle"
                  v-if="index3 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="`study_units-${study_unit.id}`"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge3"
                      v-for="study_unit in learning_result.study_units"
                      :key="'p-lrs3' + study_unit.id"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="`evaluation_criteria-${evaluation_criteria.id}`"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge4"
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="'p-ecs4' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index3 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Ev {{ evaluation.type_name }} {{ index3 + 1 }} - -->
                  {{ evaluation.name }}
                </b-td>
                <b-td
                  :class="[
                    evaluation.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="evaluation.study_environments.length > 0">
                    <span>
                      {{
                        evaluation.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ evaluation.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
        </template>
        <template
          v-if="
            suitcase_report.transversal_exams &&
            suitcase_report.transversal_exams.length > 0
          "
        >
          <template
            v-for="transversal_exam in suitcase_report.transversal_exams"
          >
            <b-tr :key="`transversal_exam-${transversal_exam.id}`">
              <b-td colspan="3">
                <!-- Examen
                {{ transversal_exam.type_name }} -->
              </b-td>
              <b-td class="text-left">{{ transversal_exam.name }}</b-td>
              <b-td
                :class="[
                  transversal_exam.study_environments.length > 0
                    ? 'text-left'
                    : 'text-ceter',
                ]"
                ><template
                  v-if="transversal_exam.study_environments.length > 0"
                >
                  <div
                    v-for="(
                      environment, index
                    ) in transversal_exam.study_environments"
                    :key="`environment-${environment.id}`"
                    variant="info"
                    class="mr-1"
                  >
                    {{ index > 0 ? ", " : "" }}{{ environment.name }}
                  </div>
                </template></b-td
              >
              <b-td>{{ transversal_exam.suggested_hours }}</b-td>
            </b-tr>
          </template>
        </template>
        <template
          v-if="
            suitcase_report.learning_experiences.length > 0 ||
            suitcase_report.transversal_exams.length > 0
          "
        >
          <b-tr>
            <b-td colspan="5"> TOTAL</b-td>
            <b-td>{{
              suitcase_report.total_egress_profile_matter_evaluation_hours
            }}</b-td>
          </b-tr>
        </template>
        <template
          v-if="
            suitcase_report.learning_results.length == 0 &&
            suitcase_report.transversal_exams.length == 0
          "
        >
          <b-td class="text-center" colspan="6"
            >No hay Síntesis de la Ruta de Aprendizaje.</b-td
          >
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE EVALUACIONES PARCIALES  ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="5" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="star"></b-icon>
            </span>
            4. EVALUACIONES
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="second-header">EVALUACIÓN</b-th>
          <b-th class="second-header"> SITUACIÓN EVALUATIVA</b-th>
          <b-th class="second-header"> DESCRIPCIÓN </b-th>
          <b-th class="second-header">
            PONDERACIÓN DE LA EVALUACIÓN PARCIAL (%)</b-th
          >
          <b-th class="second-header"> PONDERACIÓN FINAL</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.partial_evaluations &&
            suitcase_report.partial_evaluations.length > 0
          "
        >
          <b-tr
            v-for="(evaluation, index) in suitcase_report.partial_evaluations"
            :key="`evaluation-${evaluation.id}`"
          >
            <b-td class="text-left">{{ evaluation.name }}</b-td>
            <b-td class="text-left">
              {{
                evaluation.evaluation_situations.length == 0
                  ? "N/A"
                  : evaluation.evaluation_situations.join(", ")
              }}
            </b-td>
            <b-td class="text-left">
              <div
                v-html="evaluation.description"
                class="rich-text-content"
              ></div>
            </b-td>
            <b-td>{{ evaluation.weighing }}%</b-td>
            <b-td
              class="text-center align-middle"
              v-if="index == 0"
              :rowspan="suitcase_report.partial_evaluations.length"
              >{{ getTotalWeighing + "%" }}
            </b-td>
          </b-tr>
          <template
            v-if="
              suitcase_report.transversal_exams &&
              suitcase_report.transversal_exams.length > 0
            "
          >
            <template
              v-for="transversal_exam in suitcase_report.transversal_exams"
            >
              <b-tr :key="`transversal_exam-${transversal_exam.id}`">
                <b-td class="text-left">{{ transversal_exam.name }}</b-td>
                <b-td class="text-left">
                  {{
                    transversal_exam.evaluation_situations.length > 0
                      ? transversal_exam.evaluation_situations.join(", ")
                      : "N/A"
                  }}</b-td
                >
                <b-td class="text-left">
                  <div
                    v-html="transversal_exam.description"
                    class="rich-text-content"
                  ></div>
                </b-td>
                <b-td>-</b-td>
                <b-td>{{ transversal_exam.weighing + "%" }}</b-td>
              </b-tr>
            </template>
          </template>
          <template>
            <b-tr>
              <b-td colspan="4"> TOTAL</b-td>
              <b-td>{{ getAllTotalWeighing }}%</b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-tr>
            <b-td class="text-center" colspan="5"
              >No hay Evaluaciones Parciales.</b-td
            >
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE BIBLIOGRAFÍA OBLIGATORIA  ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="6" class="first-header test text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="gear-wide-connected"></b-icon>
            </span>
            5. BIBLIOGRAFÍA OBLIGATORIA
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <div
              v-for="resource in suitcase_report.bibliographic_resources.filter(
                (x) => this.$equals(x.type__name, 'Obligatoria')
              )"
              :key="`resource-${resource.id}`"
              class="rich-text-content bibliography-content"
            >
              <span v-html="resource.description"></span>
            </div>
            <div
              v-if="suitcase_report.bibliographic_resources.length == 0"
              class="text-center"
            >
              No hay Bibliografía Obligatoria.
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="true"
    ></EvaluationCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningSuitcaseStudentView",
  components: {
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      // egress_profile_linkage: null,
      suitcase_report: null,
      default_color: "var(--primary-font-color)",
      default_bg_color: "#f8f9fa",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      images: names.IMAGES,
    }),
    egressProfileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    egressProfile() {
      if (!this.egressProfileMatters) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.egressProfileMatter.egress_profile
      );
    },
    facultyColor() {
      if (this.suitcase_report) {
        if (this.suitcase_report.matter_is_transversal) return "black";
        else return this.suitcase_report.faculty_color;
      }
      return "black";
    },
    // egressProfileLinked() {
    //   if (!this.egress_profile_linkage) return null;
    //   return this.egressProfiles.find(
    //     (x) => x.id == this.egress_profile_linkage.linked_egress_profile
    //   );
    // },
    getTotalWeighing() {
      let total_weighing = 0;
      let total_weighing_sixty = 0;
      if (this.suitcase_report) {
        this.suitcase_report.partial_evaluations.forEach((x) => {
          total_weighing = total_weighing + x.weighing;
        });
        total_weighing_sixty = total_weighing * 0.6;
      }
      return total_weighing_sixty;
    },
    getAllTotalWeighing() {
      let total_partial = this.getTotalWeighing;
      let total_transversal = 0;
      let total_evaluations = 0;

      if (this.suitcase_report) {
        this.suitcase_report.transversal_exams.forEach((x) => {
          total_transversal = total_transversal + x.weighing;
        });
        total_evaluations = total_partial + total_transversal;
      }
      return total_evaluations;
    },
  },
  methods: {
    fetchSuitcaseReport() {
      if (this.egress_profile_matter_id) {
        this.$restful
          .Get(
            `/teaching/learning_suitcase/?egress_profile_matter=${this.egress_profile_matter_id}&learning_suitcase_option=5`
          )
          .then((response) => {
            this.suitcase_report = response;
          });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  mounted() {
    this.fetchSuitcaseReport();
    if (this.egress_profile_matter_id)
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.egress_profile_matter_id
        )
        .then((egress_profile_matter) => {
          this.$store
            .dispatch(names.FETCH_MATTER, egress_profile_matter.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `PA - ${matter.code} ${matter.name}`
              );
            });
          // this.$restful
          //   .Get(
          //     `/mesh/egress-profile-linkage/?linker_egress_profile=${egress_profile_matter.egress_profile}`
          //   )
          //   .then((response) => {
          //     if (response[0]) {
          //       this.egress_profile_linkage = response[0];
          //       this.$store.dispatch(
          //         names.FETCH_EGRESS_PROFILE,
          //         response[0].linked_egress_profile
          //       );
          //     }
          //   });
          this.$store.dispatch(
            names.FETCH_EGRESS_PROFILE,
            egress_profile_matter.egress_profile
          );
        });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.ra-badge,
.il-badge {
  border: 1px solid black !important;
  font-size: 9pt;
  letter-spacing: 0.3px;
}
.learning-result-badge1,
.learning-result-badge2,
.learning-result-badge3,
.evaluation-criteria-badge1,
.evaluation-criteria-badge2,
.evaluation-criteria-badge3,
.evaluation-criteria-badge4 {
  display: none;
}
.suitcase-header {
  display: flex;
  position: relative;
  height: 56px;
  align-items: center;
}
.suitcase-faculty {
  width: 100%;
  margin-left: 1%;
  margin-right: 1em;
}
.suitcase-title {
  max-width: 100px;
  vertical-align: top;
  font-weight: bold;
  letter-spacing: 2.5px !important;
  line-height: 0.9;
}
.suitcase-title > .suitcase-title-2 {
  letter-spacing: -2px !important;
  font-weight: 100 !important;
}
.logo-container {
  display: flex;
  margin-left: auto;
  margin-right: 5em;
}
.logo-section {
  border-left: 1px solid;
  padding-left: 1em;
}
.logo-section > .institution-logo {
  max-height: 50px;
  transform: translateY(-25%);
  z-index: 1;
}
.left-header-text {
  width: 20%;
  margin-left: 1px;
}
.synthesis-header-container {
  display: flex;
  margin-left: 1px;
}
.empty-block {
  width: 5%;
}
.synthesis-header {
  background-color: black !important;
  width: 95%;
  margin-right: 1px;
  z-index: 10;
}
.first-header {
  background-color: #808080 !important;
  color: white;
}
.second-header {
  background-color: #d9d9d9 !important;
}
.thirth-header {
  background-color: #bfbfbf !important;
}
.general-background-icon {
  padding: 0.3em;
  border-radius: 30px;
  border: 1px dotted;
}
.pre-requisites-tag {
  font-size: var(--secondary-font-size);
}
@media print {
  .badge {
    border: none;
  }
  .bibliography-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 900px !important;
  }
  .learning-result-badge1,
  .learning-result-badge2,
  .learning-result-badge3,
  .evaluation-criteria-badge1,
  .evaluation-criteria-badge2,
  .evaluation-criteria-badge3,
  .evaluation-criteria-badge4 {
    display: flex;
    text-align: justify;
  }
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
</style>